import React from "react"
import { Fade } from "react-awesome-reveal"
import ChartTurists from "../images/chartTurists"

const InvestorsInfoFive = () => (
	<section className="investor-info investor-info--five">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-5 investor-info__image investor-info__image-holder">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <ChartTurists />
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="bold">
                            Szacunkowa liczba gości odwiedzających Kraków w latach 2005-2019
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"left"} triggerOnce>
                        <p className="investor-info__source">
                            *źródło: BIP Miasto Kraków{' '}
                            <a href="https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta">
                                https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta
                            </a>
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Rośnie liczba turystów <br />
                            odwiedzających miasto
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Ruch turystyczny w Krakowie na przestrzeni ostatniej dekady stale rośnie. Liczba osób 
                            odwiedzających miasto przekroczyła 3 mln osób w roku 2019. Turyści krajowi i 
                            zagraniczni stanowili w tymże roku 72,2% ogółu odwiedzających, a odwiedzający 
                            jednodniowi krajowi i zagraniczni: 27,8% ogółu. Te dane pokazują, że jeśli decydujemy 
                            się na pobyt w Krakowie poszukujemy noclegu na dłużej niż 1 dobę. 
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"right"} triggerOnce>
                        <div className="content__info">
                            <p className="h3 font-regular info__text">
                                3 mln osób w 2019r. <span>Liczba osób odwiedzających miasto</span>
                            </p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoFive
