import React from "react"

const ChartPrices = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 707.06 473.98" className="chart-prices mb-40">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g id="Group_442" data-name="Group 442">
                    <g id="Rectangle_227" data-name="Rectangle 227">
                        <rect className="cls-1" x="78.56" y="42.5" width="50" height="381" />
                    </g>
                    <g id="Rectangle_228" data-name="Rectangle 228">
                        <rect className="cls-1" x="158.56" y="59.5" width="50" height="364" />
                    </g>
                    <g id="Rectangle_229" data-name="Rectangle 229">
                        <rect className="cls-1" x="239.56" y="63.5" width="50" height="360" />
                    </g>
                    <g id="Rectangle_230" data-name="Rectangle 230">
                        <rect className="cls-1" x="320.56" y="76.5" width="50" height="347" />
                    </g>
                    <g id="Rectangle_231" data-name="Rectangle 231">
                        <rect className="cls-1" x="400.56" y="171.5" width="50" height="252" />
                    </g>
                    <g id="Rectangle_232" data-name="Rectangle 232">
                        <rect className="cls-1" x="483.56" y="291.5" width="50" height="132" />
                    </g>
                    <g id="Rectangle_233" data-name="Rectangle 233">
                        <rect className="cls-1" x="566.56" y="80.5" width="50" height="343" />
                    </g>
                    <g id="Rectangle_234" data-name="Rectangle 234">
                        <rect className="cls-1" x="645.56" y="334.5" width="50" height="89" />
                    </g>
                    <g id="do_35" data-name="do 35" className="cls-2">
                        <g className="cls-2">
                            <path d="M83.25,466.09c0-2.9,1.8-4.66,3.75-4.66a3.21,3.21,0,0,1,2.3,1L89.21,461v-3.19h2.13V470.5H89.61l-.16-.95h-.07a3.72,3.72,0,0,1-2.49,1.17C84.68,470.72,83.25,469,83.25,466.09Zm6,1.89v-4.07a2.49,2.49,0,0,0-1.76-.72c-1.1,0-2,1-2,2.86s.72,2.9,2,2.9A2.24,2.24,0,0,0,89.21,468Z" />
                            <path d="M93.37,466.09a4.31,4.31,0,0,1,4.23-4.66c2.2,0,4.21,1.71,4.21,4.66a4.24,4.24,0,1,1-8.44,0Zm6.27,0c0-1.77-.76-2.92-2-2.92s-2,1.15-2,2.92.75,2.9,2,2.9S99.64,467.84,99.64,466.09Z" />
                            <path d="M106.56,469.1l1-1.35a3.82,3.82,0,0,0,2.7,1.24c1.23,0,2.09-.63,2.09-1.69s-.76-1.93-3.35-1.93v-1.55c2.24,0,2.95-.76,2.95-1.8s-.61-1.49-1.65-1.49a3.31,3.31,0,0,0-2.29,1.06l-1.1-1.32a5.17,5.17,0,0,1,3.48-1.44c2.2,0,3.71,1.1,3.71,3a2.74,2.74,0,0,1-2,2.63v.07a2.9,2.9,0,0,1,2.37,2.84c0,2.09-1.83,3.32-4,3.32A5,5,0,0,1,106.56,469.1Z" />
                            <path d="M115.81,469.13l1-1.37a3.82,3.82,0,0,0,2.66,1.23,2,2,0,0,0,2.18-2.16,1.93,1.93,0,0,0-2.07-2.11,2.92,2.92,0,0,0-1.82.63l-1-.65.35-5.65h6.15v1.8h-4.3l-.25,2.7a3.25,3.25,0,0,1,1.49-.36,3.33,3.33,0,0,1,3.6,3.58,3.86,3.86,0,0,1-4,4A5.27,5.27,0,0,1,115.81,469.13Z" />
                        </g>
                    </g>
                    <g id="_30" data-name=" 30" className="cls-2">
                        <g className="cls-2">
                            <path d="M80,14.1l1-1.35A3.8,3.8,0,0,0,83.73,14c1.23,0,2.09-.63,2.09-1.7s-.76-1.92-3.35-1.92V8.82c2.23,0,3-.76,3-1.8s-.61-1.49-1.65-1.49a3.31,3.31,0,0,0-2.29,1.06l-1.1-1.32a5.17,5.17,0,0,1,3.48-1.44c2.19,0,3.71,1.1,3.71,3a2.74,2.74,0,0,1-2,2.63v.07A2.9,2.9,0,0,1,88,12.4c0,2.09-1.83,3.32-4,3.32A5,5,0,0,1,80,14.1Z" />
                            <path d="M89.56,9.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S89.56,13.59,89.56,9.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.87,1-1.87,4.23S92.39,14,93.47,14,95.34,13,95.34,9.72Z" />
                            <path d="M98.94,17.64a2.47,2.47,0,0,0,1.84-2.19h-.16a1.27,1.27,0,0,1-1.36-1.3,1.32,1.32,0,0,1,1.41-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M103.53,14.1l1-1.35a3.8,3.8,0,0,0,2.7,1.24c1.22,0,2.09-.63,2.09-1.7s-.76-1.92-3.35-1.92V8.82c2.23,0,3-.76,3-1.8s-.61-1.49-1.65-1.49A3.29,3.29,0,0,0,105,6.59l-1.1-1.32a5.17,5.17,0,0,1,3.48-1.44c2.19,0,3.71,1.1,3.71,3a2.74,2.74,0,0,1-2,2.63v.07a2.91,2.91,0,0,1,2.38,2.84c0,2.09-1.84,3.32-4,3.32A5,5,0,0,1,103.53,14.1Z" />
                            <path d="M112.89,7.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S117.36,11,115.7,11,112.89,9.59,112.89,7.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S117,9,117,7.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM121.35,12c0-2.36,1.16-3.71,2.81-3.71S127,9.61,127,12s-1.17,3.75-2.83,3.75S121.35,14.35,121.35,12Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S125.46,13.74,125.46,12Z" />
                        </g>
                    </g>
                    <g id="m2" className="cls-2">
                        <g className="cls-2">
                            <path d="M17.15,461.55h2.27l.2,1.12h.07a3.7,3.7,0,0,1,2.7-1.33,2.48,2.48,0,0,1,2.47,1.47,3.87,3.87,0,0,1,2.79-1.47c1.94,0,2.81,1.38,2.81,3.61v5.55H27.68v-5.19c0-1.24-.34-1.62-1-1.62a2.21,2.21,0,0,0-1.44.81v6H22.41v-5.19c0-1.24-.33-1.62-1.05-1.62a2.25,2.25,0,0,0-1.44.81v6H17.15Z" />
                        </g>
                        <g className="cls-2">
                            <path d="M31.9,463.5c1.64-1.45,2.86-2.63,2.86-3.56a.84.84,0,0,0-.91-.95,1.66,1.66,0,0,0-1.15.66l-.84-.84a2.75,2.75,0,0,1,2.22-1.1,2.07,2.07,0,0,1,2.23,2.13c0,1.2-1.09,2.28-2.21,3.37.34,0,.8-.07,1.11-.07h1.43v1.36H31.9Z" />
                        </g>
                    </g>
                    <g id="_2020" data-name=" 2020" className="cls-2">
                        <g className="cls-2">
                            <path d="M.07,15.79C2.88,13.3,5,11.27,5,9.69A1.45,1.45,0,0,0,3.42,8.05a2.88,2.88,0,0,0-2,1.13L0,7.74A4.7,4.7,0,0,1,3.8,5.85,3.55,3.55,0,0,1,7.63,9.51c0,2.05-1.87,3.9-3.8,5.78.6-.06,1.39-.13,1.91-.13H8.19V17.5H.07Z" />
                            <path d="M9.63,11.74c0-3.91,1.66-5.89,4.14-5.89s4.14,2,4.14,5.89-1.65,6-4.14,6S9.63,15.63,9.63,11.74Zm5.65,0c0-3.1-.68-3.73-1.51-3.73s-1.51.63-1.51,3.73.67,3.83,1.51,3.83S15.28,14.82,15.28,11.74Z" />
                            <path d="M19.22,15.79c2.81-2.49,4.9-4.52,4.9-6.1a1.45,1.45,0,0,0-1.55-1.64,2.88,2.88,0,0,0-2,1.13L19.15,7.74A4.7,4.7,0,0,1,23,5.85a3.56,3.56,0,0,1,3.84,3.66c0,2.05-1.88,3.9-3.8,5.78.59-.06,1.38-.13,1.91-.13h2.44V17.5H19.22Z" />
                            <path d="M28.78,11.74c0-3.91,1.66-5.89,4.14-5.89s4.14,2,4.14,5.89-1.65,6-4.14,6S28.78,15.63,28.78,11.74Zm5.66,0c0-3.1-.69-3.73-1.52-3.73s-1.51.63-1.51,3.73.67,3.83,1.51,3.83S34.44,14.82,34.44,11.74Z" />
                        </g>
                    </g>
                    <g id="_2017" data-name=" 2017" className="cls-2">
                        <g className="cls-2">
                            <path d="M.07,426.79c2.81-2.48,4.9-4.52,4.9-6.1a1.45,1.45,0,0,0-1.55-1.64,2.88,2.88,0,0,0-2,1.13L0,418.74a4.7,4.7,0,0,1,3.8-1.89,3.55,3.55,0,0,1,3.83,3.66c0,2.05-1.87,3.9-3.8,5.78.6-.06,1.39-.13,1.91-.13H8.19v2.34H.07Z" />
                            <path d="M9.63,422.74c0-3.91,1.66-5.89,4.14-5.89s4.14,2,4.14,5.89-1.65,6-4.14,6S9.63,426.63,9.63,422.74Zm5.65,0c0-3.1-.68-3.73-1.51-3.73s-1.51.63-1.51,3.73.67,3.83,1.51,3.83S15.28,425.82,15.28,422.74Z" />
                            <path d="M19.78,426.25h2.49v-6.39H20.12v-1.73A7.44,7.44,0,0,0,23,417.07h2.06v9.18h2.12v2.25h-7.4Z" />
                            <path d="M34,419.41H28.93v-2.34h8v1.73c-2.72,3.2-3.15,5.25-3.37,9.7H30.8A14.71,14.71,0,0,1,34,419.41Z" />
                        </g>
                    </g>
                    <g id="_35-40" data-name=" 35-40" className="cls-2">
                        <g className="cls-2">
                            <path d="M162.58,469.1l1-1.35a3.78,3.78,0,0,0,2.7,1.24c1.22,0,2.09-.63,2.09-1.69s-.76-1.93-3.35-1.93v-1.55c2.23,0,2.95-.76,2.95-1.8s-.61-1.49-1.66-1.49a3.29,3.29,0,0,0-2.28,1.06l-1.1-1.32a5.16,5.16,0,0,1,3.47-1.44c2.2,0,3.71,1.1,3.71,3a2.74,2.74,0,0,1-2,2.63v.07a2.91,2.91,0,0,1,2.38,2.84c0,2.09-1.84,3.32-4.05,3.32A4.94,4.94,0,0,1,162.58,469.1Z" />
                            <path d="M171.83,469.13l1-1.37a3.84,3.84,0,0,0,2.67,1.23,2,2,0,0,0,2.18-2.16,1.93,1.93,0,0,0-2.07-2.11,2.9,2.9,0,0,0-1.82.63l-1-.65.34-5.65h6.16v1.8H175l-.26,2.7a3.29,3.29,0,0,1,1.5-.36,3.33,3.33,0,0,1,3.6,3.58,3.87,3.87,0,0,1-4,4A5.25,5.25,0,0,1,171.83,469.13Z" />
                            <path d="M181.41,465.19h4.3v1.55h-4.3Z" />
                            <path d="M191.83,467.58h-5v-1.45l4.52-7.08h2.52v6.88h1.42v1.65h-1.42v2.92h-2Zm0-1.65v-2.52c0-.67.07-1.73.11-2.4h-.07c-.29.6-.62,1.17-.94,1.78l-2,3.14Z" />
                            <path d="M196.44,464.72c0-3.87,1.53-5.89,3.9-5.89s3.91,2,3.91,5.89-1.51,6-3.91,6S196.44,468.59,196.44,464.72Zm5.78,0c0-3.22-.78-4.23-1.88-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S202.22,468,202.22,464.72Z" />
                        </g>
                    </g>
                    <g id="_28" data-name=" 28" className="cls-2">
                        <g className="cls-2">
                            <path d="M160.27,31.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.12,3.12,0,0,0-2.21,1.24l-1.14-1.12a4.61,4.61,0,0,1,3.64-1.82,3.37,3.37,0,0,1,3.64,3.5c0,2.14-1.95,4.1-4.33,6.48.62-.06,1.37-.11,1.93-.11H168v1.8h-7.77Z" />
                            <path d="M169.58,29.57a3.38,3.38,0,0,1,2-2.94v-.07a3.27,3.27,0,0,1-1.56-2.67c0-1.87,1.47-3.06,3.47-3.06a3.07,3.07,0,0,1,3.39,3.1,3.39,3.39,0,0,1-1.55,2.61v.07a3.34,3.34,0,0,1,2,3c0,1.77-1.55,3.12-3.89,3.12S169.58,31.42,169.58,29.57Zm5.8,0c0-1.23-1.19-1.68-2.74-2.31a2.75,2.75,0,0,0-1.19,2.13,1.89,1.89,0,0,0,2.07,1.85A1.67,1.67,0,0,0,175.38,29.53Zm-.22-5.47a1.59,1.59,0,0,0-1.65-1.72A1.46,1.46,0,0,0,172,23.89c0,1.12,1,1.62,2.27,2.13A2.79,2.79,0,0,0,175.16,24.06Z" />
                            <path d="M178.94,34.64a2.47,2.47,0,0,0,1.84-2.19h-.16a1.27,1.27,0,0,1-1.36-1.3,1.32,1.32,0,0,1,1.41-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M183.89,27.12c0-4.43,2.16-6.29,4.52-6.29a4.13,4.13,0,0,1,3.08,1.3l-1.14,1.3a2.56,2.56,0,0,0-1.83-.87c-1.41,0-2.58,1.06-2.67,4.18a3.43,3.43,0,0,1,2.49-1.33c2,0,3.33,1.15,3.33,3.54a3.58,3.58,0,0,1-3.62,3.77C185.82,32.72,183.89,31,183.89,27.12Zm2,1.13c.21,2,1,2.85,2.12,2.85.94,0,1.68-.76,1.68-2.15s-.67-2-1.77-2A2.49,2.49,0,0,0,185.91,28.25Z" />
                            <path d="M192.89,24.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S197.36,28,195.7,28,192.89,26.59,192.89,24.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S197,26,197,24.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM201.35,29c0-2.36,1.16-3.71,2.81-3.71S207,26.61,207,29s-1.17,3.75-2.83,3.75S201.35,31.35,201.35,29Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S205.46,30.74,205.46,29Z" />
                        </g>
                    </g>
                    <g id="_40-50" data-name=" 40-50" className="cls-2">
                        <g className="cls-2">
                            <path d="M248.51,467.58h-5v-1.45l4.52-7.08h2.52v6.88h1.42v1.65h-1.42v2.92h-2Zm0-1.65v-2.52c0-.67.07-1.73.11-2.4h-.07c-.29.6-.61,1.17-.94,1.78l-2,3.14Z" />
                            <path d="M253.12,464.72c0-3.87,1.53-5.89,3.91-5.89s3.9,2,3.9,5.89-1.51,6-3.9,6S253.12,468.59,253.12,464.72Zm5.78,0c0-3.22-.78-4.23-1.87-4.23s-1.88,1-1.88,4.23S256,469,257,469,258.9,468,258.9,464.72Z" />
                            <path d="M262.41,465.19h4.3v1.55h-4.3Z" />
                            <path d="M267.9,469.13l1-1.37a3.82,3.82,0,0,0,2.66,1.23,2,2,0,0,0,2.18-2.16,1.93,1.93,0,0,0-2.07-2.11,2.92,2.92,0,0,0-1.82.63l-1-.65.35-5.65h6.15v1.8H271l-.25,2.7a3.28,3.28,0,0,1,1.49-.36,3.33,3.33,0,0,1,3.6,3.58,3.86,3.86,0,0,1-4.05,4A5.22,5.22,0,0,1,267.9,469.13Z" />
                            <path d="M277.44,464.72c0-3.87,1.53-5.89,3.9-5.89s3.91,2,3.91,5.89-1.51,6-3.91,6S277.44,468.59,277.44,464.72Zm5.78,0c0-3.22-.78-4.23-1.88-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S283.22,468,283.22,464.72Z" />
                        </g>
                    </g>
                    <g id="_28-2" data-name=" 28" className="cls-2">
                        <g className="cls-2">
                            <path d="M241.27,35.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.12,3.12,0,0,0-2.21,1.24l-1.14-1.12a4.61,4.61,0,0,1,3.64-1.82,3.37,3.37,0,0,1,3.64,3.5c0,2.14-1.95,4.1-4.33,6.48.62-.06,1.37-.11,1.93-.11H249v1.8h-7.77Z" />
                            <path d="M250.58,33.57a3.38,3.38,0,0,1,2-2.94v-.07a3.27,3.27,0,0,1-1.56-2.67c0-1.87,1.47-3.06,3.47-3.06a3.07,3.07,0,0,1,3.39,3.1,3.39,3.39,0,0,1-1.55,2.61v.07a3.34,3.34,0,0,1,2,3c0,1.77-1.55,3.12-3.89,3.12S250.58,35.42,250.58,33.57Zm5.8,0c0-1.23-1.19-1.68-2.74-2.31a2.75,2.75,0,0,0-1.19,2.13,1.89,1.89,0,0,0,2.07,1.85A1.67,1.67,0,0,0,256.38,33.53Zm-.22-5.47a1.59,1.59,0,0,0-1.65-1.72A1.46,1.46,0,0,0,253,27.89c0,1.12,1,1.62,2.27,2.13A2.79,2.79,0,0,0,256.16,28.06Z" />
                            <path d="M259.94,38.64a2.47,2.47,0,0,0,1.84-2.19h-.16a1.27,1.27,0,0,1-1.36-1.3,1.32,1.32,0,0,1,1.41-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M264.78,35.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.16,3.16,0,0,0-2.22,1.24l-1.13-1.12a4.6,4.6,0,0,1,3.64-1.82,3.36,3.36,0,0,1,3.63,3.5c0,2.14-1.94,4.1-4.32,6.48.61-.06,1.37-.11,1.93-.11h2.93v1.8h-7.76Z" />
                            <path d="M273.89,28.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S278.36,32,276.7,32,273.89,30.59,273.89,28.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S278,30,278,28.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM282.35,33c0-2.36,1.16-3.71,2.81-3.71S288,30.61,288,33s-1.17,3.75-2.83,3.75S282.35,35.35,282.35,33Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S286.46,34.74,286.46,33Z" />
                        </g>
                    </g>
                    <g id="_50-60" data-name=" 50-60" className="cls-2">
                        <g className="cls-2">
                            <path d="M324.58,469.13l1-1.37a3.84,3.84,0,0,0,2.67,1.23,2,2,0,0,0,2.17-2.16,1.93,1.93,0,0,0-2.07-2.11,2.87,2.87,0,0,0-1.81.63l-1-.65.34-5.65H332v1.8h-4.31l-.25,2.7a3.29,3.29,0,0,1,1.5-.36,3.33,3.33,0,0,1,3.6,3.58,3.87,3.87,0,0,1-4.05,4A5.23,5.23,0,0,1,324.58,469.13Z" />
                            <path d="M334.12,464.72c0-3.87,1.53-5.89,3.91-5.89s3.9,2,3.9,5.89-1.51,6-3.9,6S334.12,468.59,334.12,464.72Zm5.78,0c0-3.22-.78-4.23-1.87-4.23s-1.88,1-1.88,4.23S337,469,338,469,339.9,468,339.9,464.72Z" />
                            <path d="M343.41,465.19h4.3v1.55h-4.3Z" />
                            <path d="M349.26,465.12c0-4.43,2.16-6.29,4.52-6.29a4.11,4.11,0,0,1,3.07,1.3l-1.13,1.3a2.56,2.56,0,0,0-1.84-.87c-1.4,0-2.57,1.06-2.66,4.18a3.43,3.43,0,0,1,2.48-1.33c2,0,3.34,1.15,3.34,3.54a3.58,3.58,0,0,1-3.62,3.77C351.18,470.72,349.26,469,349.26,465.12Zm2,1.13c.22,2,1,2.85,2.13,2.85.93,0,1.67-.76,1.67-2.15s-.66-2-1.76-2A2.5,2.5,0,0,0,351.27,466.25Z" />
                            <path d="M358.44,464.72c0-3.87,1.53-5.89,3.9-5.89s3.91,2,3.91,5.89-1.51,6-3.91,6S358.44,468.59,358.44,464.72Zm5.78,0c0-3.22-.78-4.23-1.88-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S364.22,468,364.22,464.72Z" />
                        </g>
                    </g>
                    <g id="_26" data-name=" 26" className="cls-2">
                        <g className="cls-2">
                            <path d="M322.27,48.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.12,3.12,0,0,0-2.21,1.24l-1.14-1.12a4.61,4.61,0,0,1,3.64-1.82,3.37,3.37,0,0,1,3.64,3.5c0,2.14-1.95,4.1-4.33,6.48.62-.06,1.37-.11,1.93-.11H330v1.8h-7.77Z" />
                            <path d="M331.63,44.12c0-4.43,2.17-6.29,4.52-6.29a4.12,4.12,0,0,1,3.08,1.3l-1.13,1.3a2.58,2.58,0,0,0-1.84-.87c-1.4,0-2.57,1.06-2.66,4.18a3.39,3.39,0,0,1,2.48-1.33c2,0,3.33,1.15,3.33,3.54a3.58,3.58,0,0,1-3.62,3.77C333.56,49.72,331.63,48,331.63,44.12Zm2,1.13c.22,2,1,2.85,2.13,2.85.93,0,1.67-.76,1.67-2.15s-.67-2-1.76-2A2.5,2.5,0,0,0,333.65,45.25Z" />
                            <path d="M340.94,51.64a2.46,2.46,0,0,0,1.84-2.19h-.16a1.26,1.26,0,0,1-1.35-1.3,1.31,1.31,0,0,1,1.4-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M345.94,48.42l1.14-1.3a2.56,2.56,0,0,0,1.84.87c1.42,0,2.57-1.07,2.66-4.18a3.43,3.43,0,0,1-2.48,1.33c-2,0-3.34-1.15-3.34-3.54a3.57,3.57,0,0,1,3.62-3.77c2.24,0,4.16,1.73,4.16,5.6,0,4.43-2.16,6.29-4.52,6.29A4.23,4.23,0,0,1,345.94,48.42Zm3.55-4.81a2.48,2.48,0,0,0,2-1.33c-.22-2-1-2.83-2.13-2.83-.93,0-1.67.76-1.67,2.15S348.39,43.61,349.49,43.61Z" />
                            <path d="M354.89,41.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S359.36,45,357.7,45,354.89,43.59,354.89,41.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S359,43,359,41.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM363.35,46c0-2.36,1.16-3.71,2.81-3.71S369,43.61,369,46s-1.17,3.75-2.83,3.75S363.35,48.35,363.35,46Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S367.46,47.74,367.46,46Z" />
                        </g>
                    </g>
                    <g id="_70-80" data-name=" 70-80" className="cls-2">
                        <g className="cls-2">
                            <path d="M493.34,460.85h-5.4v-1.8h7.71v1.3c-2.74,3.36-3.28,5.69-3.48,10.15H490A15.79,15.79,0,0,1,493.34,460.85Z" />
                            <path d="M497.12,464.72c0-3.87,1.53-5.89,3.91-5.89s3.9,2,3.9,5.89-1.51,6-3.9,6S497.12,468.59,497.12,464.72Zm5.78,0c0-3.22-.78-4.23-1.87-4.23s-1.88,1-1.88,4.23S500,469,501,469,502.9,468,502.9,464.72Z" />
                            <path d="M506.41,465.19h4.3v1.55h-4.3Z" />
                            <path d="M512.2,467.57a3.37,3.37,0,0,1,2.06-2.94v-.07a3.26,3.26,0,0,1-1.57-2.67c0-1.87,1.48-3.06,3.47-3.06a3.07,3.07,0,0,1,3.39,3.1,3.36,3.36,0,0,1-1.55,2.61v.07a3.35,3.35,0,0,1,2,3c0,1.77-1.55,3.12-3.89,3.12S512.2,469.42,512.2,467.57Zm5.8,0c0-1.22-1.19-1.68-2.74-2.3a2.71,2.71,0,0,0-1.18,2.12,1.89,1.89,0,0,0,2.07,1.85A1.66,1.66,0,0,0,518,467.53Zm-.22-5.47a1.58,1.58,0,0,0-1.65-1.71,1.46,1.46,0,0,0-1.55,1.54c0,1.12,1,1.62,2.27,2.13A2.79,2.79,0,0,0,517.78,462.06Z" />
                            <path d="M521.44,464.72c0-3.87,1.53-5.89,3.9-5.89s3.91,2,3.91,5.89-1.51,6-3.91,6S521.44,468.59,521.44,464.72Zm5.78,0c0-3.22-.78-4.23-1.88-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S527.22,468,527.22,464.72Z" />
                        </g>
                    </g>
                    <g id="_13" data-name=" 13" className="cls-2">
                        <g className="cls-2">
                            <path d="M485.92,262.77h2.56v-7.44h-2.13V254a7.7,7.7,0,0,0,2.67-.95h1.58v9.72h2.24v1.73h-6.92Z" />
                            <path d="M494.27,263.1l1-1.35A3.82,3.82,0,0,0,498,263c1.23,0,2.09-.63,2.09-1.7s-.75-1.92-3.35-1.92v-1.55c2.24,0,3-.76,3-1.8s-.62-1.49-1.66-1.49a3.33,3.33,0,0,0-2.29,1.06l-1.1-1.32a5.19,5.19,0,0,1,3.48-1.44c2.2,0,3.71,1.1,3.71,3a2.74,2.74,0,0,1-2,2.63v.07a2.9,2.9,0,0,1,2.37,2.84c0,2.09-1.83,3.32-4,3.32A5,5,0,0,1,494.27,263.1Z" />
                            <path d="M503.94,266.64a2.47,2.47,0,0,0,1.84-2.19h-.17a1.27,1.27,0,0,1-1.35-1.3,1.32,1.32,0,0,1,1.41-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M508.78,263.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.14,3.14,0,0,0-2.22,1.24l-1.13-1.12a4.6,4.6,0,0,1,3.64-1.82,3.36,3.36,0,0,1,3.63,3.5c0,2.14-1.94,4.1-4.32,6.48.61-.06,1.37-.11,1.93-.11h2.93v1.8h-7.76Z" />
                            <path d="M517.89,256.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S522.36,260,520.7,260,517.89,258.59,517.89,256.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S522,258,522,256.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM526.35,261c0-2.36,1.16-3.71,2.81-3.71S532,258.61,532,261s-1.17,3.75-2.83,3.75S526.35,263.35,526.35,261Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S530.46,262.74,530.46,261Z" />
                        </g>
                    </g>
                    <g id="_60-70" data-name=" 60-70" className="cls-2">
                        <g className="cls-2">
                            <path d="M404.94,465.12c0-4.43,2.16-6.29,4.52-6.29a4.15,4.15,0,0,1,3.08,1.3l-1.14,1.3a2.56,2.56,0,0,0-1.83-.87c-1.41,0-2.58,1.06-2.67,4.18a3.43,3.43,0,0,1,2.49-1.33c2,0,3.33,1.15,3.33,3.54a3.58,3.58,0,0,1-3.62,3.77C406.87,470.72,404.94,469,404.94,465.12Zm2,1.13c.21,2,1,2.85,2.12,2.85.94,0,1.67-.76,1.67-2.15s-.66-2-1.76-2A2.47,2.47,0,0,0,407,466.25Z" />
                            <path d="M414.12,464.72c0-3.87,1.53-5.89,3.91-5.89s3.9,2,3.9,5.89-1.51,6-3.9,6S414.12,468.59,414.12,464.72Zm5.78,0c0-3.22-.78-4.23-1.87-4.23s-1.88,1-1.88,4.23S417,469,418,469,419.9,468,419.9,464.72Z" />
                            <path d="M423.41,465.19h4.3v1.55h-4.3Z" />
                            <path d="M434.66,460.85h-5.4v-1.8H437v1.3c-2.73,3.36-3.27,5.69-3.47,10.15h-2.16A15.73,15.73,0,0,1,434.66,460.85Z" />
                            <path d="M438.44,464.72c0-3.87,1.53-5.89,3.9-5.89s3.91,2,3.91,5.89-1.51,6-3.91,6S438.44,468.59,438.44,464.72Zm5.78,0c0-3.22-.78-4.23-1.88-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S444.22,468,444.22,464.72Z" />
                        </g>
                    </g>
                    <g id="_25" data-name=" 25" className="cls-2">
                        <g className="cls-2">
                            <path d="M402.27,143.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.12,3.12,0,0,0-2.21,1.24l-1.14-1.12a4.61,4.61,0,0,1,3.64-1.82,3.37,3.37,0,0,1,3.64,3.5c0,2.14-1.95,4.1-4.33,6.48.62-.06,1.37-.11,1.93-.11H410v1.8h-7.77Z" />
                            <path d="M411.27,143.13l1-1.37a3.82,3.82,0,0,0,2.66,1.23,2,2,0,0,0,2.18-2.16,1.93,1.93,0,0,0-2.07-2.11,2.92,2.92,0,0,0-1.82.63l-1-.65.35-5.65h6.15v1.8h-4.3l-.25,2.7a3.25,3.25,0,0,1,1.49-.36,3.33,3.33,0,0,1,3.6,3.58,3.86,3.86,0,0,1-4.05,3.95A5.27,5.27,0,0,1,411.27,143.13Z" />
                            <path d="M420.94,146.64a2.46,2.46,0,0,0,1.84-2.19h-.16a1.26,1.26,0,0,1-1.35-1.3,1.31,1.31,0,0,1,1.4-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M425.78,143.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.16,3.16,0,0,0-2.22,1.24l-1.13-1.12a4.6,4.6,0,0,1,3.64-1.82,3.36,3.36,0,0,1,3.63,3.5c0,2.14-1.94,4.1-4.32,6.48.61-.06,1.37-.11,1.93-.11h2.93v1.8h-7.76Z" />
                            <path d="M434.89,136.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S439.36,140,437.7,140,434.89,138.59,434.89,136.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S439,138,439,136.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM443.35,141c0-2.36,1.16-3.71,2.81-3.71S449,138.61,449,141s-1.17,3.75-2.83,3.75S443.35,143.35,443.35,141Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S447.46,142.74,447.46,141Z" />
                        </g>
                    </g>
                    <g id="_80-100" data-name=" 80-100" className="cls-2">
                        <g className="cls-2">
                            <path d="M566.26,467.57a3.38,3.38,0,0,1,2-2.94v-.07a3.27,3.27,0,0,1-1.56-2.67c0-1.87,1.47-3.06,3.47-3.06a3.07,3.07,0,0,1,3.39,3.1,3.39,3.39,0,0,1-1.55,2.61v.07a3.35,3.35,0,0,1,2,3c0,1.77-1.55,3.12-3.89,3.12S566.26,469.42,566.26,467.57Zm5.8,0c0-1.22-1.19-1.68-2.74-2.3a2.72,2.72,0,0,0-1.19,2.12,1.89,1.89,0,0,0,2.07,1.85A1.67,1.67,0,0,0,572.06,467.53Zm-.22-5.47a1.58,1.58,0,0,0-1.66-1.71,1.45,1.45,0,0,0-1.54,1.54c0,1.12,1,1.62,2.26,2.13A2.76,2.76,0,0,0,571.84,462.06Z" />
                            <path d="M575.49,464.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S575.49,468.59,575.49,464.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S581.27,468,581.27,464.72Z" />
                            <path d="M584.78,465.19h4.3v1.55h-4.3Z" />
                            <path d="M591.17,468.77h2.56v-7.43H591.6V460a7.7,7.7,0,0,0,2.67-.95h1.58v9.72h2.23v1.73h-6.91Z" />
                            <path d="M599.81,464.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S599.81,468.59,599.81,464.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.88,1-1.88,4.23.8,4.32,1.88,4.32S605.59,468,605.59,464.72Z" />
                            <path d="M609.06,464.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S609.06,468.59,609.06,464.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.87,1-1.87,4.23S611.89,469,613,469,614.84,468,614.84,464.72Z" />
                        </g>
                    </g>
                    <g id="_26-2" data-name=" 26" className="cls-2">
                        <g className="cls-2">
                            <path d="M568.27,52.19c3.14-2.92,5.17-5,5.17-6.72a1.74,1.74,0,0,0-1.87-1.94,3.12,3.12,0,0,0-2.21,1.24l-1.14-1.12a4.61,4.61,0,0,1,3.64-1.82,3.37,3.37,0,0,1,3.64,3.5c0,2.14-2,4.1-4.33,6.48.62-.06,1.37-.11,1.93-.11H576v1.8h-7.77Z" />
                            <path d="M577.63,48.12c0-4.43,2.17-6.29,4.52-6.29a4.12,4.12,0,0,1,3.08,1.3l-1.13,1.3a2.58,2.58,0,0,0-1.84-.87c-1.4,0-2.57,1.06-2.66,4.18a3.39,3.39,0,0,1,2.48-1.33c2,0,3.33,1.15,3.33,3.54a3.58,3.58,0,0,1-3.62,3.77C579.56,53.72,577.63,52,577.63,48.12Zm2,1.13c.22,2,1,2.85,2.13,2.85.93,0,1.67-.76,1.67-2.15s-.67-2-1.76-2A2.5,2.5,0,0,0,579.65,49.25Z" />
                            <path d="M586.94,55.64a2.47,2.47,0,0,0,1.84-2.19h-.16a1.27,1.27,0,0,1-1.36-1.3,1.32,1.32,0,0,1,1.41-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M591.53,52.13l1-1.37A3.84,3.84,0,0,0,595.17,52a2,2,0,0,0,2.18-2.16,1.93,1.93,0,0,0-2.07-2.11,2.9,2.9,0,0,0-1.82.63l-1-.65.34-5.65H599v1.8h-4.3l-.26,2.7a3.29,3.29,0,0,1,1.5-.36,3.33,3.33,0,0,1,3.6,3.58,3.87,3.87,0,0,1-4,3.95A5.25,5.25,0,0,1,591.53,52.13Z" />
                            <path d="M600.89,45.22c0-2.36,1.15-3.71,2.81-3.71s2.83,1.35,2.83,3.71S605.36,49,603.7,49,600.89,47.59,600.89,45.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.3.72-1.3,2.49.56,2.52,1.3,2.52S605,47,605,45.22Zm5.49-3.71h1.29l-6.41,12.21h-1.29ZM609.35,50c0-2.36,1.16-3.71,2.81-3.71S615,47.61,615,50s-1.17,3.75-2.83,3.75S609.35,52.35,609.35,50Zm4.11,0c0-1.76-.56-2.48-1.3-2.48s-1.29.72-1.29,2.48.55,2.52,1.29,2.52S613.46,51.74,613.46,50Z" />
                        </g>
                    </g>
                    <g id="pow._100" data-name="pow. 100" className="cls-2">
                        <g className="cls-2">
                            <path d="M637.55,461.64h1.74l.17.94h0a4.33,4.33,0,0,1,2.66-1.15c2.2,0,3.48,1.78,3.48,4.51,0,3-1.8,4.78-3.76,4.78a3.45,3.45,0,0,1-2.27-1l.07,1.48V474h-2.14Zm5.92,4.32c0-1.74-.58-2.77-1.89-2.77a2.72,2.72,0,0,0-1.89,1v4.09a2.66,2.66,0,0,0,1.73.7C642.57,469,643.47,467.94,643.47,466Z" />
                            <path d="M647.14,466.09a4.31,4.31,0,0,1,4.23-4.66c2.2,0,4.21,1.71,4.21,4.66a4.24,4.24,0,1,1-8.44,0Zm6.27,0c0-1.77-.76-2.92-2-2.92s-2,1.15-2,2.92.75,2.9,2,2.9S653.41,467.84,653.41,466.09Z" />
                            <path d="M656.63,461.66h2.12l1,4.59a25.64,25.64,0,0,1,.46,2.63h.07c.16-1,.3-1.84.48-2.63l1.08-4.59H664l1.12,4.59c.2.79.36,1.59.52,2.63h.07c.17-1,.27-1.84.46-2.63l1-4.59h2L667,470.5h-2.65l-.9-4.1c-.18-.8-.32-1.52-.48-2.67h-.08a20.67,20.67,0,0,1-.45,2.67l-.9,4.1H659Z" />
                            <path d="M669.64,469.29a1.37,1.37,0,1,1,1.37,1.43A1.38,1.38,0,0,1,669.64,469.29Z" />
                            <path d="M678.44,468.77H681v-7.43h-2.13V460a7.7,7.7,0,0,0,2.67-.95h1.58v9.72h2.24v1.73h-6.92Z" />
                            <path d="M687.08,464.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S687.08,468.59,687.08,464.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.87,1-1.87,4.23S689.91,469,691,469,692.86,468,692.86,464.72Z" />
                            <path d="M696.33,464.72c0-3.87,1.53-5.89,3.91-5.89s3.91,2,3.91,5.89-1.52,6-3.91,6S696.33,468.59,696.33,464.72Zm5.78,0c0-3.22-.77-4.23-1.87-4.23s-1.87,1-1.87,4.23.79,4.32,1.87,4.32S702.11,468,702.11,464.72Z" />
                        </g>
                    </g>
                    <g id="_8" data-name=" 8" className="cls-2">
                        <g className="cls-2">
                            <path d="M652,304.57a3.38,3.38,0,0,1,2-2.94v-.07a3.26,3.26,0,0,1-1.57-2.67c0-1.87,1.48-3.06,3.48-3.06a3.06,3.06,0,0,1,3.38,3.1,3.36,3.36,0,0,1-1.55,2.61v.07a3.35,3.35,0,0,1,2,3c0,1.77-1.55,3.12-3.89,3.12S652,306.42,652,304.57Zm5.79,0c0-1.22-1.19-1.68-2.73-2.3a2.69,2.69,0,0,0-1.19,2.12,1.89,1.89,0,0,0,2.07,1.85A1.66,1.66,0,0,0,657.75,304.53Zm-.21-5.47a1.59,1.59,0,0,0-1.66-1.71,1.46,1.46,0,0,0-1.55,1.54c0,1.12,1,1.62,2.27,2.13A2.8,2.8,0,0,0,657.54,299.06Z" />
                            <path d="M661.31,309.64a2.45,2.45,0,0,0,1.84-2.19H663a1.26,1.26,0,0,1-1.35-1.3,1.31,1.31,0,0,1,1.4-1.3c1,0,1.57.81,1.57,2.18a3.88,3.88,0,0,1-2.85,3.78Z" />
                            <path d="M666.32,306.42l1.13-1.3a2.58,2.58,0,0,0,1.84.87c1.42,0,2.57-1.06,2.66-4.18a3.39,3.39,0,0,1-2.48,1.33c-2,0-3.33-1.15-3.33-3.54a3.57,3.57,0,0,1,3.62-3.77c2.23,0,4.16,1.73,4.16,5.6,0,4.43-2.16,6.29-4.52,6.29A4.19,4.19,0,0,1,666.32,306.42Zm3.55-4.81a2.48,2.48,0,0,0,2-1.33c-.22-2-1-2.83-2.12-2.83s-1.68.76-1.68,2.15S668.77,301.61,669.87,301.61Z" />
                            <path d="M675.26,299.22c0-2.36,1.16-3.71,2.81-3.71s2.83,1.35,2.83,3.71-1.17,3.74-2.83,3.74S675.26,301.59,675.26,299.22Zm4.11,0c0-1.77-.56-2.49-1.3-2.49s-1.29.72-1.29,2.49.55,2.52,1.29,2.52S679.37,301,679.37,299.22Zm5.49-3.71h1.3l-6.41,12.21h-1.3ZM683.73,304c0-2.36,1.15-3.71,2.81-3.71s2.82,1.35,2.82,3.71-1.17,3.75-2.82,3.75S683.73,306.35,683.73,304Zm4.1,0c0-1.76-.56-2.48-1.29-2.48s-1.3.72-1.3,2.48.56,2.52,1.3,2.52S687.83,305.74,687.83,304Z" />
                        </g>
                    </g>
                    <g id="Line_100" data-name="Line 100">
                        <rect x="52.56" width="1" height="444" />
                    </g>
                    <g id="Line_101" data-name="Line 101">
                        <rect x="53.06" y="443.5" width="654" height="1" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default ChartPrices