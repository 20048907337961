import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoSeven = () => (
	<section className="investor-info investor-info--seven">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-5 investor-info__image investor-info__image-holder">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info7.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Wygodny obiekt <br />
                            dla rodzin z dziećmi
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Budynek stanowi także atrakcyjną lokalizację dla rodzin z dziećmi. W dzielnicy 
                            Krowodrza znajduje się aż 16 publicznych i 21 niepublicznych przedszkoli, a także szkoły 
                            podstawowe i licea. Lokalizacja w centrum miasta i dobre połączenie komunikacją 
                            miejską w inne rejony Krakowa znacznie ułatwia dotarcie także do placówek 
                            zlokalizowanych w innych dzielnicach.  
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoSeven
