import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoTwo = () => (
	<section className="investor-info investor-info--reverse investor-info--second">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 investor-info__content">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Kraków nadal na <br />
                            topie mimo kryzysu
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="mb-30">
                            Osłabienie ekonomiczne kraju wskutek kryzysu wywołanego pandemią Covid-19 nie 
                            wpłynęło na spadek zainteresowania mieszkaniami w Krakowie. Zgodnie z najnowszymi 
                            raportami* ceny mieszkań stale rosną, a mieszkania dobrze się sprzedają. Według analiz 
                            danych Instytutu <span className="semi-bold">Analiz Monitor Rynku Nieruchomości deweloperzy w 
                            realizowanych inwestycjach sprzedali 70% mieszkań w I kwartale 2020 roku<span className="color--main">*</span>. </span>
                            Kraków to nadal jedna z topowych lokalizacji na terenie kraju.
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"left"} triggerOnce>
                        <p className="investor-info__source">
                            *źródło: 
                                <a href="https://www.housemarket.pl/rynek_mieszkaniowy/101/krakow_mimo_koronawirusa_nie_bedzie_znacznego_spadku_cen_nieruchomosci,22986.html">
                                    https://www.housemarket.pl/rynek_mieszkaniowy/101/krakow_mimo_koronawirusa_nie_bedzie_znacznego_spadku_cen_nieruchomosci,22986.html
                                </a>
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 investor-info__image">
                    <Fade delay={500} direction={"right"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info2.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoTwo
