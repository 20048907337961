import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoEight = () => (
	<section className="investor-info investor-info--eight">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 col-xxl-7 offset-xxl-1 investor-info__title-holder investor-info__title-holder--left">
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <h2 className="investor-info__title">
                            Zalety obiektu
                        </h2>
                    </Fade>
                </div>
            </div>
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-5 investor-info__image">
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info8.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Wysoki standard <br />
                            inwestycji
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p>
                            KW51 to inwestycja, w której starannie dopracowany jest każdy, najdrobniejszy detal. 
                            Estetyka i dokładność wykończenia to cechy szczególne obiektu. Budynek został 
                            rzetelnie zaplanowany i zrealizowany z dbałością o detale, które mają ogromne 
                            znaczenie dla świadomych i wymagających nabywców.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoEight
