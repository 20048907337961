import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoTen = () => (
	<section className="investor-info investor-info--ten">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-5 investor-info__image">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info10.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Bezproblemowa adaptacja mieszkania na dwie kawalerki
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p>
                            Mieszkania zostały zaprojektowane z myślą o nabywcach, których interesuje przyszły 
                            najem lokalu. Układ pomieszczeń oferuje idealne warunki do zaadaptowania 
                            pojedynczego mieszkania na dwie oddzielne kawalerki, których wynajem cieszy się dużą 
                            popularnością w Krakowie.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoTen
