import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoFour = () => (
	<section className="investor-info investor-info--reverse investor-info--four">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 investor-info__content">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Duże zainteresowanie <br />
                            mieszkaniami na Krowodrzy
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="mb-30">
                            Ulica Kazimierza Wielkiego znajduje się w ścisłym centrum dzielnicy Krowodrza. To 
                            dzielnica o największym zagęszczeniu deweloperskich inwestycji. Wynika to z 
                            postrzegania tej dzielnicy jako najbardziej atrakcyjnej, pod względem stosunku ceny do 
                            lokalizacji<span className="color--main">*</span>
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"left"} triggerOnce>
                        <p className="bold small">
                            *wg. raportu Krakowski rynek nieruchomości 2018
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 investor-info__image">
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info4.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoFour
