import React from "react"
import { Link } from "gatsby"

const InvestorApartments = () => (
  <section className="investor-info investor-info--eleven">
    <Link class="btn" to="/mieszkania/">
      Zobacz mieszkania
    </Link>
  </section>
)

export default InvestorApartments
