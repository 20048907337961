import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoSix = () => (
	<section className="investor-info investor-info--reverse investor-info--six">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-9 offset-lg-3 investor-info__title-holder investor-info__title-holder--right">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="investor-info__title">
                            Zalety dzielnicy
                        </h2>
                    </Fade>
                </div>
            </div>
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 investor-info__content">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Idealna lokalizacja dla młodych, aktywnych osób
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p>
                            Nieruchomość zlokalizowana jest w pobliżu ważnych punktów na mapie Krakowa. To 
                            idealne miejsce dla młodych, aktywnych osób, które poszukują lokalizacji blisko 
                            centrum miasta z dobrym dojazdem do pracy. Budynek mieszkalny KW51 znajduje się w 
                            bliskiej odległości od centrum biurowego Biprostal (450m), licznych biurowców na ul. 
                            Armii Krajowej, wydziałów szkół wyższych oraz Akademii Górniczo-Hutniczej.
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 investor-info__image">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info6.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoSix
