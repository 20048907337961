import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsText = () => (
	<section className="investor-text">
        <div className="container">
            <div className="row investor-text__holder">
                <div className="col-12 col-lg-4 col-xxl-3 offset-xxl-1">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <p className="investor-text__text investor-text__text--first mb-30">
                            <span className="bold">Kraków</span> to drugie pod względem wielkości i 
                            zaludnienia miasto w Polsce. Liczba 
                            zameldowanych mieszkańców w czerwcu zeszłego 
                            roku wyniosła 774,8 tys., zaś w całym 
                            województwie małopolskim mieszka niemal 3,5 
                            mln osób. 
                        </p>
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="investor-text__text">
                            Kraków zdecydowanie wyróżnia się na tle innych 
                            miast w kraju. Jest to jednocześnie miasto 
                            akademickie, biznesowe i turystyczne. 
                            Przybywającym na studia oferuje atrakcyjne 
                            kierunki rozwoju, szukającym pracy dobrze płatne 
                            stanowiska pracy, a turystom liczne atrakcje.
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-7 col-xxl-6 offset-xxl-1 investor-text__icon-holder">
                    <Fade delay={250} direction={"bottom"} triggerOnce>
                        <p className="investor-text__title big-number">
                            774,8 <span>tys</span>
                        </p>
                    </Fade>
                    <Fade delay={250} direction={"top"} triggerOnce>
                        <p className="investor-text__subtitle h3 mb-40">
                            Liczba zameldowanych mieszkańców
                        </p>
                    </Fade>
                    <div className="investor-text__info">
                        <Fade delay={150} direction={"top"} triggerOnce>
                            <div className="info__item">
                                <div className="item__icon-holder mb-40">
                                    <img data-src={ require('../../assets/img/investor-icon1.svg') } alt="" className="item__icon lazyload" />
                                </div>
                                <h3 className="item__title">
                                    Miasto akademickie
                                </h3>
                            </div>
                        </Fade>
                        <Fade delay={250} direction={"top"} triggerOnce>
                            <div className="info__item">
                                <div className="item__icon-holder mb-40">
                                    <img data-src={ require('../../assets/img/investor-icon2.svg') } alt="" className="item__icon lazyload"/>
                                </div>
                                <h3 className="item__title">
                                    Miasto biznesowe
                                </h3>
                            </div>
                        </Fade>
                        <Fade delay={500} direction={"top"} triggerOnce>
                            <div className="info__item">
                                <div className="item__icon-holder mb-40">
                                    <img data-src={ require('../../assets/img/investor-icon3.svg') } alt="" className="item__icon lazyload" />
                                </div>
                                <h3 className="item__title">
                                    Miasto turystyczne
                                </h3>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsText
