import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoNine = () => (
	<section className="investor-info investor-info--reverse investor-info--nine">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 investor-info__content">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Unikalny design 
                            i charakter obiektu
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="mb-30">
                            Zarówno fasada budynku, jak i jego wnętrze zachwyca oryginalnymi wykończeniami i 
                            niebywałą estetyką. Projekt inspirowany jest kulturą japońską oraz regułami feng shui. 
                            Wszystko po to, by zapewnić przyszłym Lokatorom spokój i harmonię. Inwestycja 
                            wyróżnia się na tle podobnych ofert na rynku i stanowi jedyny w takim rodzaju punkt na 
                            mapie Krakowa. Design i jakość obiektu stanowią jego wartość dodaną.
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 investor-info__image investor-info__image-holder">
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info9.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoNine
