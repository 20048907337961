import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import InvestorsText from "../components/forInvestor/investorsText"
import InvestorsInfoOne from "../components/forInvestor/investorsInfoOne"
import InvestorsInfoTwo from "../components/forInvestor/investorsInfoTwo"
import InvestorsInfoThree from "../components/forInvestor/investorsInfoThree"
import InvestorsInfoFour from "../components/forInvestor/investorsInfoFour"
import InvestorsInfoFive from "../components/forInvestor/investorsInfoFive"
import InvestorsInfoSix from "../components/forInvestor/investorsInfoSix"
import InvestorsInfoSeven from "../components/forInvestor/investorsInfoSeven"
import InvestorsInfoEight from "../components/forInvestor/investorsInfoEight"
import InvestorsInfoNine from "../components/forInvestor/investorsInfoNine"
import InvestorsInfoTen from "../components/forInvestor/investorsInfoTen"
import InvestorApartments from "../components/forInvestor/investorApartments"

const ForInvestors = () => (
  <Layout>
    <SEO title="Dla inwestora" />
    <Banner title="Dla inwestora" />
    <InvestorsText />
    <InvestorsInfoOne />
    <InvestorsInfoTwo />
    <InvestorsInfoThree />
    <InvestorsInfoFour />
    <InvestorsInfoFive />
    <InvestorsInfoSix />
    <InvestorsInfoSeven />
    <InvestorsInfoEight />
    <InvestorsInfoNine />
    <InvestorsInfoTen />
    <InvestorApartments />
  </Layout>
)

export default ForInvestors
