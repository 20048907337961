import React from "react"
import { Fade } from "react-awesome-reveal"

const InvestorsInfoOne = () => (
	<section className="investor-info investor-info--first">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 col-xxl-7 offset-xxl-1 investor-info__title-holder investor-info__title-holder--left">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <h2 className="investor-info__title">
                            Zalety rynku
                        </h2>
                    </Fade>
                </div>
            </div>
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-5 investor-info__image">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/investors-info1.jpg') } alt="" className="image-shadow lazyload"/>
                    </Fade>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Sytuacja ekonomiczna mieszkańców Krakowa poprawia się<span>*</span>
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Sytuacja ekonomiczna mieszkańców polepsza się. Według danych GUS (styczeń 2020) przeciętne wynagrodzenie w sektorze przedsiębiorstw w Krakowie wyniosło 5 968,56 zł brutto, o 711 zł więcej niż średnia płaca dla województwa. W tym mieście odnotowano także najniższą stopę bezrobocia w Małopolsce, wynoszącą 2 proc. 
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Polepszająca się jakość życia mieszkańców wpływa na rosnący popyt na inwestycje mieszkaniowe. Liczba mieszkań oddanych do użytkowania w samym Krakowie to 2062 w styczniu 2020 roku. 
                        </p>
                    </Fade>
                    <Fade delay={750} direction={"right"} triggerOnce>
                        <p className="investor-info__source">
                            *źródło: <a href="https://www.propertynews.pl/inwestycje/w-krakowie-ceny-nieruchomosci-w-gore-jest-drogo-czy-bedzie-drozej,81251.html">https://www.propertynews.pl/inwestycje/w-krakowie-ceny-nieruchomosci-w-gore-jest-drogo-czy-bedzie-drozej,81251.html</a>
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoOne
