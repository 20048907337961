import React from "react"
import { Fade } from "react-awesome-reveal"
import ChartPrices from "../images/chartPrices"

const InvestorsInfoThree = () => (
    <section className="investor-info investor-info--three">
        <div className="container">
            <div className="row investor-info__holder">
                <div className="col-12 col-lg-5 col-xxl-6 investor-info__image investor-info__image-holder">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <ChartPrices />
                    </Fade>
                    <Fade delay={250} direction={"left"} triggerOnce>
                        <p className="bold mb-10">
                            Procentowy wzrost cen mieszkań w zależnośc od powierzchni, miedzy rokiem 2017 a 2020
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"left"} triggerOnce>
                        <p className="investor-info__source">
                            *źródło: 
                            <a href="https://www.pb.pl/ceny-mieszkan/krakow">
                            https://www.pb.pl/ceny-mieszkan/krakow
                            </a>
                        </p>
                    </Fade>
                </div>
                <div className="col-12 col-lg-5 offset-lg-1 col-xxl-5 offset-xxl-0 investor-info__content">
                    <Fade delay={150} direction={"right"} triggerOnce>
                        <h2 className="content__title mb-70">
                            Stale rosnące <br />
                            ceny mieszkań w Krakowie
                        </h2>
                    </Fade>
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Jako miasto turystyczne, atrakcyjny pod względem inwestycyjnym Kraków na początku 
                            2020 roku zaskoczył analityków 4-procentowym wzrostem stawek na rynku pierwotnym.
                        </p>
                    </Fade>
                    <Fade delay={500} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Obserwowany od lat wzrost cen mieszkań na krakowskim rynku deweloperskim nie 
                            zniechęca nabywców, którzy nadal inwestują w nieruchomości w Krakowie. Zwłaszcza 
                            te położone w dobrej lokalizacji, blisko centrum. 
                        </p>
                    </Fade>
                    <Fade delay={750} direction={"right"} triggerOnce>
                        <p className="mb-30">
                            Wzrost cen mieszkań w zależności od metrażu sięgnął nawet ponad 30%. Największe 
                            wzrosty zanotowano w przypadku mieszkań o mniejszej powierzchni.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default InvestorsInfoThree
